import axios from 'axios';
import authHeader from './auth-header';

const urls = {
  development: 'http://localhost:8080/',
  production: 'https://nifty-anthem-326801.uc.r.appspot.com/',
};

const API_URL =  urls[process.env.NODE_ENV];

class UserService {
  getPublicContent() {
    return axios.get(API_URL + 'all');
  }

  getUserBoard() {
    return axios.get(API_URL + 'user', { headers: authHeader() });
  }

  getModeratorBoard() {
    return axios.get(API_URL + 'mod', { headers: authHeader() });
  }

  getAdminBoard() {
    return axios.get(API_URL + 'admin', { headers: authHeader() });
  }
}

export default new UserService();
